<template>
  <div class="wrap">
    <Title name="我的证书"></Title>

    <div class="container">
      <div class="tabel-content">
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: '#f5f5f5',
            fontWeight: 500,
            color: '#666',
            padding: '12 5px',
          }"
          style="width: 100%"
        >
          <el-table-column prop="sequenceNo" label="序号" width="130"></el-table-column>
          <el-table-column prop="issueTime" label="发布时间" align="center"></el-table-column>
          <el-table-column prop="effectiveTime" label="有效期" align="center">
            <template slot-scope="scope">
              {{scope.row.startTime}} ~ {{scope.row.endTime}}
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination" v-if="pageManage.total > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pageManage.total"
            :page-size="pageManage.limit"
            :current-page="pageManage.page"
            @current-change="changeCurrentPage"
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "../../component/Title";
import { tcredentialsigninfo } from "../../api";
export default {
  components: {
    Title,
  },
  data() {
    return {
      tableData: [],
      pageManage: {
        limit: 10,
        page: 1,
        total: 0,
      }
    };
  },

  created() {
    this.getMyCertificate(0);
  },

  methods: {
    changeCurrentPage(num) {
      this.pageManage.page = num;
      this.getMyCertificate(1);
    },
    getMyCertificate(type){
      let _this = this
      //0 代表查询按钮点击进来的，默认显示第一页数据
      //1 代表分页点进来的
      if (type==0){
        this.pageManage.page=1
      }
      tcredentialsigninfo({
        pageNo:this.pageManage.page,
        pageSize: this.pageManage.limit
      }).then(res=>{
        _this.tableData = res.data.list
        _this.pageManage.total = res.data.total
      })
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  background: #fff;

  .container {
    padding: 10px;
  }
  .pagination {
    padding: 12px 0;
  }
}
</style>
